"use client";
import { useTranslations } from "next-intl";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";
import { Box, Flex } from "@radix-ui/themes";
import { LayoutContainerV2 } from "@/design-system/components/layout-container/LayoutContainerV2";
import styles from "./ClientTestimonials.module.scss";
import { TextV2 } from "@/design-system/components/text/TextV2";

function ClientTestimonials() {
  return (
    <Box py={{ initial: "8", sm: "9" }}>
      <Carousel
        showArrows={true}
        showIndicators={true}
        infiniteLoop={true}
        dynamicHeight={false}
        showStatus={false}
        swipeable={true}
        swipeScrollTolerance={50}
        showThumbs={false}
        emulateTouch={true}
        width={"100%"}
        autoPlay={true}
        transitionTime={800}
        interval={5000}
        stopOnHover={true}
        // https://github.com/leandrowd/react-responsive-carousel/issues/717
        // Enable vertical scroll on mobile
        preventMovementUntilSwipeScrollTolerance={true}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                className={styles.indicatorSelected}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={`Selected: ${label} ${index + 1}`}
              />
            );
          }
          return (
            <li
              className={styles.indicator}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        <ClientTestimonial key={"1"} testimonialKey={"testimonial_1"} />
        <ClientTestimonial key={"2"} testimonialKey={"testimonial_2"} />
        <ClientTestimonial key={"3"} testimonialKey={"testimonial_3"} />
        <ClientTestimonial key={"4"} testimonialKey={"testimonial_4"} />
      </Carousel>
    </Box>
  );
}

function ClientTestimonial({
  testimonialKey,
}: {
  testimonialKey:
    | "testimonial_1"
    | "testimonial_2"
    | "testimonial_3"
    | "testimonial_4";
}) {
  const t = useTranslations(`ClientTestimonials.${testimonialKey}`);
  return (
    <LayoutContainerV2
      size={"3"}
      style={{
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Flex
        direction={"column"}
        gap={{ initial: "7", sm: "9" }}
        mb={"8"}
        justify={"center"}
      >
        <TextV2 textStyle={"Headline XL"}>
          {t.rich("quote", {
            green: (chunks) => (
              <span style={{ color: "var(--colorV2-dark-green" }}>
                {chunks}
              </span>
            ),
          })}
        </TextV2>
        <TextV2 textStyle={"Headline S"}>{t("source")}</TextV2>
      </Flex>
    </LayoutContainerV2>
  );
}

export { ClientTestimonials };
