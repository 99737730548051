import * as Checkbox from "@radix-ui/react-checkbox";
import * as RadixIcons from "@radix-ui/react-icons";
import { styled } from "styled-components";
import { useState } from "react";
import { CheckedState } from "@radix-ui/react-checkbox";

type PropsWithClassName = { className?: string };
type LabelCheckboxProps = PropsWithClassName & {
  name: string;
  value: string;
  label: string;
  checked?: boolean;
  autofocus?: boolean;
  onCheckedChange?: (selected: boolean) => void;
};

function LabelCheckbox(props: LabelCheckboxProps) {
  const [checked, setChecked] = useState(props.checked);

  const handleLabelClick = (event: React.MouseEvent<HTMLLabelElement>) => {
    setChecked(!checked);
    if (props.onCheckedChange) {
      props.onCheckedChange(!checked);
    }
    event.preventDefault();
  };

  const handleCheckedChange = (checkedState: CheckedState) => {
    if (checkedState) {
      setChecked(true);
    } else {
      setChecked(false);
    }
    if (props.onCheckedChange) {
      props.onCheckedChange(checkedState === true);
    }
  };

  return (
    <CheckboxRoot
      autoFocus={props.autofocus}
      id={props.value}
      name={props.name}
      value={props.value}
      checked={checked}
      onCheckedChange={handleCheckedChange}
    >
      <CheckboxIndicator>
        <RadixIcons.CheckIcon width={24} height={24} />
      </CheckboxIndicator>
      <label onClick={handleLabelClick} htmlFor={props.value}>
        {props.label}
      </label>
    </CheckboxRoot>
  );
}

const CheckboxRoot = styled(Checkbox.Root)`
  position: relative;
  color: var(--accent-9-contrast);
  background-color: var(--colorV2-light-green);
  padding: 6px 12px;
  border-radius: 8px;
  border: 2px var(--colorV2-light-green) solid;
  cursor: pointer;
  & * {
    cursor: pointer;
  }
  &[data-state="checked"] label {
    display: block;
    margin: 0 4px 0 24px;
  }
  &[data-state="checked"] {
    border: 2px var(--colorV2-green) solid;
    background-color: var(--colorV2-green);
    color: var(--accent-9-contrast);
    &:hover {
      background-color: var(--colorV2-green-over);
      border: 2px var(--colorV2-green-over) solid;
    }
    &:focus {
      outline: none;
      background-color: var(--colorV2-green-over);
      border: 2px var(--colorV2-green) solid;
    }
  }

  &:hover {
    background-color: var(--green-low-over);
    border: 2px var(--green-low-over) solid;
  }
  &:focus {
    outline: none;
    background-color: var(--green-low-over);
    border: 2px var(--colorV2-green) solid;
  }
`;

const CheckboxIndicator = styled(Checkbox.Indicator)`
  position: absolute;
  left: 0;
  width: 24px;
  transform: translate(10px, 0px);
`;

export default LabelCheckbox;
export { LabelCheckbox };
