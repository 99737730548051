import * as api from "@/api/api";
import { Profile } from "@/api/entities/profile";

type FeaturedVideoProfiles = {
  profiles: Profile[];
};

async function featuredVideoProfiles(
  locale: string,
): Promise<api.ServerResponse<FeaturedVideoProfiles>> {
  return api.render<FeaturedVideoProfiles>(
    fetch(
      `${process.env.NEXT_PUBLIC_APP_API_URL}/directory/featured_videos?locale=${locale}`,
    ),
  );
}

export { featuredVideoProfiles };
