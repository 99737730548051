import * as api from "@/api/api";
import { City } from "@/api/entities/city";

async function city(
  city_slug: string,
  locale: string,
): Promise<api.ServerResponse<City>> {
  return await api.render<City>(
    fetch(
      `${process.env.NEXT_PUBLIC_APP_API_URL}/city/by_slug/${city_slug}?locale=${locale}`,
    ),
  );
}

export { city };
