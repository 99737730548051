import * as api from "@/api/api";
import { DirectoryPageConfig } from "@/api/entities/directoryPageConfig";

type Category = {
  id: string;
  slug: string;
  search_filter_id: string;
  analytics_id: string;
  cat_type: "speciality" | "approach";
  name: string;
  directory_page_config?: DirectoryPageConfig;
  blog_tag_id: number;
};

async function category(
  category_slug: string,
  locale: string,
): Promise<api.ServerResponse<Category>> {
  return await api.render<Category>(
    fetch(
      `${process.env.NEXT_PUBLIC_APP_API_URL}/category/by_slug/${category_slug}?locale=${locale}`,
    ),
  );
}

export { category };
export type { Category };
