"use client";
import * as analytics from "@/analytics";
import { pushDataLayerEvent } from "@/analytics/analytics.datalayer";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import React from "react";
import { useTranslations } from "next-intl";
import { GetMatchedClickedSource } from "@/analytics/analytics.directory";
import { Link } from "@/navigation";

type GetMatchedCTAProps = {
  clickSource: GetMatchedClickedSource;
  ctaText?: string; // Optional prop for custom CTA text
} & React.ComponentProps<typeof ButtonV2>;

const GetMatchedCTA = React.forwardRef(
  (
    { clickSource, ctaText, ...props }: GetMatchedCTAProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const t = useTranslations("Common");
    // Use ctaText if provided, otherwise fall back to the default translation
    const buttonText = ctaText || t("start_matching");

    return (
      <ButtonV2 ref={ref} variant={"outline"} size={"3"} {...props} asChild>
        <Link
          onClick={() => {
            analytics.directory.getMatchedClicked({ source: clickSource });
            pushDataLayerEvent("get_matched_clicked");
          }}
          style={{ cursor: "pointer" }}
          href={"/matching/survey"}
        >
          {buttonText}
        </Link>
      </ButtonV2>
    );
  },
);

GetMatchedCTA.displayName = "SearchGetMatched";

export { GetMatchedCTA };
