import * as api from "@/api/api";
import { Language } from "@/api/entities/language";

async function language(
  language_slug: string,
  locale: string,
): Promise<api.ServerResponse<Language>> {
  return await api.render<Language>(
    fetch(
      `${process.env.NEXT_PUBLIC_APP_API_URL}/language/by_slug/${language_slug}?locale=${locale}`,
    ),
  );
}

export { language };
