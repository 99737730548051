import { navigateToExternalUrl } from "@/utils/NavigationUtils";
import { render, ServerResponse } from "./api";

type AuthStates = {
  token: string | null;
  state: string | null;
};
type RegistrationDetails = AuthStates & {
  full_name: string;
  signup_type: "Client" | "Therapist";
  opt_in_to_marketing_emails: boolean;
  client_invite_cookie?: string;
  therapist_invite_coupon?: string;
  therapist_invite_reference?: string;
  registered_through_matching?: boolean;
  employee_access_code?: string;
};

type RegistrationResponse = AuthStates & {
  hashed_email: string;
};

async function register(
  registrationDetails: RegistrationDetails,
): Promise<ServerResponse<RegistrationResponse>> {
  return await render(
    fetch(`${process.env.NEXT_PUBLIC_API_URL}/registration/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${registrationDetails.token}`,
      },
      body: JSON.stringify(registrationDetails),
    }),
  );
}

function continueAuth({ token, state }: AuthStates) {
  navigateToExternalUrl(
    `${process.env.NEXT_PUBLIC_LOGIN_URL}/continue?session_token=${token}&state=${state}`,
  );
}

function startAuth0Signup(returnUrl?: URL) {
  // return_to is passed down and read by the Auth0 action "Create Account if Necessary"
  navigateToExternalUrl(
    returnUrl
      ? `/api/auth/signup?return_to=${encodeURIComponent(returnUrl.toString())}`
      : `/api/auth/signup`,
  );
}

function startAuth0Login(returnUrl?: URL) {
  // return_to is passed down and read by the Auth0 action "Create Account if Necessary"
  navigateToExternalUrl(
    returnUrl
      ? `/api/auth/login?return_to=${encodeURIComponent(returnUrl.toString())}`
      : `/api/auth/login`,
  );
}

async function validateAccessToken(): Promise<
  ServerResponse<{ valid: boolean }>
> {
  return await render(
    fetch(`/api/auth/validate`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        cache: "no-store",
      },
    }),
  );
}

export {
  continueAuth,
  register,
  startAuth0Login,
  startAuth0Signup,
  validateAccessToken,
};
