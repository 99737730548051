import { useTranslations } from "next-intl";
import { FilterCombobox } from "./FilterCombobox";
import { FilterMenuProps } from "@components/Search/Filter/FilterMenuProps";
import { FilterMenu } from "./FilterMenu";
import React from "react";
import { search as api } from "@/api";
import { Text } from "@/design-system/components/Text";
import { Flex, Separator } from "@radix-ui/themes";

function FilterMenuCategories({
  options,
  filters,
  setFilters,
}: FilterMenuProps) {
  const t = useTranslations("Search.filters.categories");

  const totalSelected =
    (filters?.approaches?.length || 0) + (filters?.specialities?.length || 0);

  return (
    <FilterMenu
      buttonLabel={t("button_label")}
      contentLabel={t("content_label")}
      totalSelected={totalSelected}
      fixedWidth={true}
    >
      <CategoriesFilter
        setFilters={setFilters}
        filters={filters}
        options={options}
        selectedSpecialtyIds={filters?.specialities || []}
        selectedApproachIds={filters?.approaches || []}
      />
    </FilterMenu>
  );
}

function StandaloneCategoriesFilter({
  options,
  filters,
  setFilters,
}: FilterMenuProps) {
  return (
    <CategoriesFilter
      setFilters={setFilters}
      filters={filters}
      options={options}
      selectedSpecialtyIds={filters?.specialities || []}
      selectedApproachIds={filters?.approaches || []}
    />
  );
}

function CategoriesFilter({
  options,
  filters,
  setFilters,
  selectedSpecialtyIds,
  selectedApproachIds,
}: {
  options: api.SearchFiltersOptions;
  filters: api.SearchParams["filters"];
  setFilters: (f: api.SearchParams["filters"]) => void;
  selectedSpecialtyIds: string[];
  selectedApproachIds: string[];
}) {
  const t = useTranslations("Search.filters.categories");
  return (
    <Flex direction={"column"} gap={"3"}>
      <Text tiny light>
        {t("specialities.label")}
      </Text>
      <FilterCombobox
        id="specialities_filter"
        ariaLabel={t("specialities.label")}
        placeholder={t("specialities.placeholder")}
        select={options.specialities}
        selected={selectedSpecialtyIds}
        enableShowAll={true}
        useAutofocus={true}
        onSelectedChange={(selected) => {
          setFilters({
            ...filters,
            specialities: selected,
          });
        }}
      />
      <Separator size="4" my={"3"} />
      <Text tiny light>
        {t("approaches.label")}
      </Text>
      <FilterCombobox
        id="methodology_filter"
        ariaLabel={t("approaches.label")}
        placeholder={t("approaches.placeholder")}
        select={{
          all: options.approaches.all,
          common: options.approaches.common,
        }}
        enableShowAll={true}
        selected={selectedApproachIds}
        useAutofocus={true}
        onSelectedChange={(selected) => {
          setFilters({
            ...filters,
            approaches: selected,
          });
        }}
      />
    </Flex>
  );
}

export { FilterMenuCategories, StandaloneCategoriesFilter };
