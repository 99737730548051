"use client"; // this is because of the search context. We can potentially remove this if we contain this context within the search component
import * as analytics from "@/analytics";
import { search as api } from "@/api";
import MonitoringService from "@lib/MonitoringService";
import { useTranslations } from "next-intl";
import React, { useCallback, useEffect } from "react";
import { SearchFallback } from "./Fallback/SearchFallback";
import * as FilterView from "./Filter/Filter";
import * as ListView from "./List/List";
import { ListInsert } from "./List/List";
import { SearchParams } from "@/api/api.directory.search";
import { useSupportedLocale } from "@/hooks/useLocale";
import { Box, Container, Flex } from "@radix-ui/themes";
import { TherapistMap } from "@components/Search/Map/TherapistMap";
import { DesktopView } from "@/design-system/responsive-helpers/DesktopView";

type SearchStatus = "searching" | "idle" | "error";
type SearchContextValue = {
  options: api.SearchFiltersOptions;

  searchParams: api.SearchParams;
  setSearchParams: (params: api.SearchParams) => void;

  result: api.SearchResponseResult | null;
  setResult: (result: api.SearchResponseResult | null) => void;

  initialMapProfiles?: api.ProfileCompact[];

  status: SearchStatus;
  setStatus: (s: SearchStatus) => void;

  suppressLocationFilter?: boolean;
};
type SearchWriteOnlyProps = {
  setSearchParams: (params: api.SearchParams) => void;
};
type SearchReadOnlyProps = {
  options: api.SearchFiltersOptions;
  searchParams: api.SearchParams | null;
  suppressLocationFilter?: boolean;
};

const SearchContext = React.createContext<SearchContextValue | null>(null);

type RootProps = {
  config: api.SearchFiltersOptions;
  initialSearchParams?: api.SearchParams;
  initialResult?: api.SearchResponseResult;
  initialMapProfiles?: api.ProfileCompact[];
  suppressLocationFilter?: boolean;
  children: React.ReactNode;
};

function Root({
  config,
  initialSearchParams,
  initialResult,
  initialMapProfiles,
  suppressLocationFilter,
  children,
}: RootProps) {
  if (initialResult && !initialSearchParams) {
    throw new Error(
      "An initialQuery is required when initialResult is provided",
    );
  }

  const [options] = React.useState(config);
  const [searchParams, setSearchParams] = React.useState(
    initialSearchParams || {},
  );
  const [result, setResult] = React.useState(initialResult || null);
  const [status, setStatus] = React.useState<SearchStatus>("idle");

  const skipFirstSearch = React.useRef(!!initialResult);
  const seed = React.useRef<string | undefined>(initialResult?.seed);
  const locale = useSupportedLocale();
  const abortController = React.useRef(new AbortController());

  React.useEffect(() => {
    async function search() {
      if (skipFirstSearch.current) {
        return;
      }

      // Cancel the previous request
      abortController.current.abort();
      abortController.current = new AbortController();

      setStatus("searching");
      api
        .search(
          {
            seed: seed?.current,
            ...searchParams,
          },
          locale,
          abortController.current.signal, // Pass the signal to your API call
        )
        .then(
          (response) => {
            if (response.status === "error") {
              setStatus("error");
            } else if (response.status === "aborted") {
              // ignore
            } else {
              seed.current = response.value.result.seed;
              setStatus("idle");
              setResult(response.value.result);
            }
          },
          () => setStatus("error"),
        );
    }

    search().catch((e) => {
      // Ignore abort errors
      if (e.name === "AbortError") {
        return;
      }
      MonitoringService.captureException(e);
      setStatus("error");
    });
    // we do not want router to be in this dependency array, as it causes a loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);
  React.useEffect(() => {
    // Note: In dev, the first search won't be skipped. This leads to a double search
    // on page load, triggering UI flicks when the profile least is shuffled.
    //
    // In dev mode, `reactStrictMode` causes the effect function passed to `React.useEffect`
    // to be called multiple-times
    // https://nextjs.org/docs/app/api-reference/next-config-js/reactStrictMode
    skipFirstSearch.current = false;
  }, []);

  return (
    <SearchContext.Provider
      value={{
        options,
        status,
        setStatus,
        searchParams: searchParams,
        setSearchParams: (searchParams: SearchParams) => {
          // The query and the result go hand-in-hand: the result must be reset when the
          // query changes, or carry the result of the active query.
          //
          // Otherwise, this is an illegal state that may lead to incorrect UI states, and
          // more importantly, incorrection analytics. For example, every query would report
          // two times 'Therapists Listing Presented', once for the left-over result and once
          // for the new active result.
          setSearchParams(searchParams);
          setResult(null);
        },
        result,
        setResult,
        initialMapProfiles,
        suppressLocationFilter: suppressLocationFilter,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

function List(props: { anchorPageTop?: string; listInserts: ListInsert[] }) {
  const t = useTranslations("Search");
  const {
    options,
    status,
    searchParams,
    setSearchParams,
    result,
    initialMapProfiles,
  } = checkContextNotNull(React.useContext(SearchContext));

  const [localTherapistsError, setLocalTherapistsError] = React.useState(false);
  useEffect(() => {
    setLocalTherapistsError(false);
  }, [searchParams]);

  const onLocalTherapistError = useCallback(() => {
    setLocalTherapistsError(true);
  }, []);

  React.useEffect(() => {
    if (result) {
      // Only track inpression when the result changes and is not null.
      analytics.directory.therapistsListingPresented(
        options,
        searchParams,
        result,
      );
    }
  }, [options, searchParams, result]);

  if (status === "error") {
    return (
      <Container size={"2"}>
        <Box my={"5"} role="alert">
          {t.rich("error", {
            mail: (chunks) => (
              <a href="mailto:support@complicated.life">{chunks}</a>
            ),
          })}
        </Box>
      </Container>
    );
  }

  return (
    <>
      {result?.is_fallback ? (
        <SearchFallback />
      ) : (
        <Flex direction={"row"} gap={"9"} width={"100%"} position={"relative"}>
          <ListView.List
            flex-grow={"1"}
            anchorPageTop={props.anchorPageTop}
            isSearching={status === "searching"}
            searchResponse={result}
            eapFilterIsOn={searchParams.filters?.isEap ?? false}
            setActiveResultPage={(page: number) => {
              setSearchParams({ ...searchParams, page });
            }}
            listInserts={props.listInserts}
          />
          {searchParams.filters?.locations?.length == 1 &&
            !localTherapistsError && (
              <DesktopView asChild>
                <Box
                  width={"50%"}
                  height={"90vh"}
                  position={"sticky"}
                  top={"80px"}
                >
                  <TherapistMap
                    searchParams={searchParams}
                    initialMapProfiles={initialMapProfiles}
                    onLocalTherapistError={onLocalTherapistError}
                  />
                </Box>
              </DesktopView>
            )}
        </Flex>
      )}
    </>
  );
}

function Filter() {
  const {
    searchParams: searchParams,
    setSearchParams: setSearchParams,
    options,
    suppressLocationFilter,
  } = checkContextNotNull(React.useContext(SearchContext));

  return (
    <FilterView.Filter
      options={options}
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      suppressLocationFilter={suppressLocationFilter}
    />
  );
}

function checkContextNotNull(context: SearchContextValue | null) {
  if (!context) {
    throw new Error("Search.Root must wrap the Seach components");
  }
  return context;
}

export { Filter, List, Root };
export type { SearchReadOnlyProps, SearchWriteOnlyProps };
