import * as api from "@/api/api";

type Company = {
  id: string;
  slug: string;
  logo_url: string;
  name: string;
  description: string;
};

async function company(
  company_slug: string,
): Promise<api.ServerResponse<Company>> {
  return await api.render<Company>(
    fetch(
      `${process.env.NEXT_PUBLIC_APP_API_URL}/company/by_slug/${company_slug}`,
    ),
  );
}

export { company };
export type { Company };
