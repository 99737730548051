import * as auth from "@/api/api.auth";
import * as blog from "@/api/api.blog";
import * as category from "@/api/api.directory.categories";
import * as city from "@/api/api.directory.cities";
import * as featuredVideoProfiles from "@/api/api.directory.videos";
import * as language from "@/api/api.directory.languages";
import * as company from "@/api/api.directory.company";
import * as faq from "@/api/api.directory.faq";
import * as search from "@/api/api.directory.search";
import * as tag from "@/api/api.directory.tags";
import * as referrals from "@/api/api.referrals";
import * as stats from "@/api/api.stats";
import * as therapySession from "@/api/api.therapy_session";
import { ServerResponse } from "./api";

export {
  auth,
  blog,
  category,
  city,
  company,
  faq,
  featuredVideoProfiles,
  language,
  search,
  stats,
  tag,
  therapySession,
  referrals,
};
export type { ServerResponse };
