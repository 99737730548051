"use client";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Map as ReactMapGlMap, Marker, Popup, useMap } from "react-map-gl";
import { ProfileCompact } from "@/api/entities/profile";
import { DirectoryCardContentMiniLayout } from "@components/Search/List/DirectoryCard";
import { Box, Card, Flex } from "@radix-ui/themes";
import { trackSearchImpression } from "@/api/api.stats";
import bbox from "@turf/bbox";
import { point } from "@turf/turf";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { useTranslations } from "next-intl";
import {
  searchForLocalTherapists,
  SearchParams,
} from "@/api/api.directory.search";
import { Skeleton } from "@/design-system/components/Skeleton";

// React Map GL example with markers and popups: https://github.com/visgl/react-map-gl/tree/7.1-release/examples/controls

function TherapistMap({
  searchParams,
  initialMapProfiles,
  onLocalTherapistError,
}: {
  searchParams: SearchParams;
  initialMapProfiles?: ProfileCompact[];
  onLocalTherapistError: () => void;
}) {
  const [skipSearch, setSkipSearch] = useState(
    !!initialMapProfiles && initialMapProfiles.length > 0,
  ); // Skip the first search to prevent unnecessary API calls
  const [isLoading, setIsLoading] = useState(true);
  const t = useTranslations("Map");
  const [profiles, setProfiles] = useState<ProfileCompact[]>(
    initialMapProfiles || [],
  ); // This is the list of therapists to display on the map
  const [selectedProfiles, setSelectedProfiles] = useState<
    ProfileCompact[] | null
  >(null);
  const groupedProfiles = useMemo(
    () => groupProfilesByLocation(profiles),
    [profiles],
  );
  const [isInteractive, setIsInteractive] = useState(false); // Control interactivity
  const trackedProfiles = useRef(new Set());
  const [viewState, setViewState] = useState(() =>
    calculateInitialViewState(profiles),
  );

  useEffect(() => {
    if (profiles.length === 0) return;
    setViewState(calculateInitialViewState(profiles));
  }, [profiles]);

  const abortController = React.useRef(new AbortController());

  useEffect(() => {
    // Reset the selected profiles when the search parameters change
    // so we aren't showing a profile that doesn't match the search
    setSelectedProfiles(null);
  }, [searchParams]);

  useEffect(() => {
    // We skip search if we have an initial result provided by the server
    if (skipSearch) {
      setIsLoading(false);
      setSkipSearch(false);
      return;
    }

    abortController.current.abort();
    abortController.current = new AbortController();
    searchForLocalTherapists(
      {
        ...searchParams.filters,
      },
      abortController.current.signal,
    ).then((localTherapistsResponse) => {
      if (localTherapistsResponse.status === "ok") {
        setIsLoading(false);
        setProfiles(localTherapistsResponse.value);
      } else if (localTherapistsResponse.status === "error") {
        onLocalTherapistError();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const pins = groupedProfiles.map((group) => (
    <ProfileMarker
      key={`${group[0].lat}-${group[0].lng}`}
      profiles={group}
      setSelectedProfiles={() => setSelectedProfiles(group)}
    />
  ));

  useEffect(() => {
    if (selectedProfiles && selectedProfiles.length > 0) {
      selectedProfiles.forEach((profile) => {
        if (!trackedProfiles.current.has(profile.user_id)) {
          (async () => {
            await trackSearchImpression(profile.user_id, "map");
            trackedProfiles.current.add(profile.user_id);
          })();
        }
      });
    }
  }, [selectedProfiles]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onMove = React.useCallback(({ viewState }: any) => {
    setViewState(viewState);
  }, []);

  return isLoading ? (
    <Skeleton kind={"box"} width={"100%"} height={"100%"} />
  ) : (
    <Box style={{ position: "relative" }} width={"100%"} height={"100%"}>
      <ReactMapGlMap
        reuseMaps
        mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_TOKEN}
        {...viewState}
        onMove={onMove}
        mapStyle={
          isInteractive
            ? "mapbox://styles/complicated-jon/clvm9kty701g501qr6u70egfx"
            : "mapbox://styles/mapbox/light-v11"
        }
        maxZoom={18}
        minZoom={8}
        scrollZoom={isInteractive}
        dragPan={isInteractive}
        dragRotate={isInteractive}
        doubleClickZoom={isInteractive}
        touchZoomRotate={isInteractive}
        boxZoom={isInteractive}
        onClick={() => {
          setIsInteractive(true);
        }}
        cursor={isInteractive ? "grab" : "pointer"}
      >
        {pins}
        {selectedProfiles && (
          <Popup
            latitude={selectedProfiles[0].lat}
            longitude={selectedProfiles[0].lng}
            maxWidth={"400px"}
            closeButton={true}
            closeOnClick={true}
            focusAfterOpen={false}
            onClose={() => setSelectedProfiles(null)}
            anchor="bottom"
            offset={14}
            style={{ width: "400px" }}
          >
            {selectedProfiles.length > 1 ? (
              <MiniProfileList profiles={selectedProfiles} />
            ) : (
              <MiniProfileLayout
                profile={selectedProfiles[0]}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open(
                    `/find-a-therapist${selectedProfiles[0].partial_uri}`,
                    "_blank",
                  );
                }}
              />
            )}
          </Popup>
        )}
      </ReactMapGlMap>
      {!isInteractive && (
        <Card
          style={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            cursor: "pointer",
          }}
          onClick={() => {
            setIsInteractive(true);
          }}
        >
          <TextV2 textStyle={"Body S"}>{t("click_to_interact")}</TextV2>
        </Card>
      )}
    </Box>
  );
}

function calculateInitialViewState(profiles: ProfileCompact[]) {
  if (profiles.length === 0) {
    return { longitude: -100, latitude: 40, zoom: 10 }; // Default view if no profiles
  }

  if (profiles.length === 1) {
    // If there's only one profile, use its coordinates as the center and set a default zoom level
    return { longitude: profiles[0].lng, latitude: profiles[0].lat, zoom: 15 };
  }

  // Calculate mean and standard deviation for lat and lng
  const latMean = profiles.reduce((sum, p) => sum + p.lat, 0) / profiles.length;
  const lngMean = profiles.reduce((sum, p) => sum + p.lng, 0) / profiles.length;
  const latStdDev = Math.sqrt(
    profiles.reduce((sum, p) => sum + Math.pow(p.lat - latMean, 2), 0) /
      profiles.length,
  );
  const lngStdDev = Math.sqrt(
    profiles.reduce((sum, p) => sum + Math.pow(p.lng - lngMean, 2), 0) /
      profiles.length,
  );

  let filteredProfiles = profiles;

  // Filter out outliers only if standard deviation is not 0
  if (latStdDev !== 0 && lngStdDev !== 0) {
    filteredProfiles = profiles.filter((p) => {
      const latZScore = (p.lat - latMean) / latStdDev;
      const lngZScore = (p.lng - lngMean) / lngStdDev;
      return latZScore > -3 && latZScore < 3 && lngZScore > -3 && lngZScore < 3;
    });
  }

  // Create an array of points for Turf
  const points = filteredProfiles.map((profile) => {
    return point([profile.lng, profile.lat]);
  });

  const features = {
    type: "FeatureCollection",
    features: points,
  };

  // Calculate the bounding box using Turf
  const [minLng, minLat, maxLng, maxLat] = bbox(features);

  // Calculate the center of the bounding box
  const centerLat = (minLat + maxLat) / 2;
  const centerLng = (minLng + maxLng) / 2;

  // Estimate zoom level based on the spread of the coordinates
  const latDiff = maxLat - minLat;
  const lngDiff = maxLng - minLng;
  const maxDiff = Math.max(latDiff, lngDiff);
  let zoom = 10;
  if (maxDiff < 0.01) zoom = 15;
  else if (maxDiff < 0.1) zoom = 13;
  else if (maxDiff < 1) zoom = 11;
  else zoom = 8;
  return { longitude: centerLng, latitude: centerLat, zoom: zoom };
}

function groupProfilesByLocation(profiles: ProfileCompact[]) {
  const grouped = new Map<string, ProfileCompact[]>();
  profiles.forEach((profile) => {
    const key = `${profile.lat}-${profile.lng}`;
    const existing = grouped.get(key);
    if (existing) {
      // insert at head or tail randomly for some variation
      const insertAtHead = Math.random() < 0.5;
      if (insertAtHead) {
        existing.unshift(profile);
      } else {
        existing.push(profile);
      }
    } else {
      grouped.set(key, [profile]);
    }
  });
  return Array.from(grouped.values());
}

function ProfileMarker({
  profiles,
  setSelectedProfiles,
}: {
  profiles: ProfileCompact[];
  setSelectedProfiles: () => void;
}) {
  const { map } = useMap();
  return (
    <Marker
      key={`${profiles[0].lat}-${profiles[0].lng}`}
      latitude={profiles[0].lat}
      longitude={profiles[0].lng}
      anchor="top"
      color="var(--colorV2-dark-green)"
      style={{ cursor: "pointer" }}
      // https://github.com/visgl/react-map-gl/issues/2411, try to remove later
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick={(e: any) => {
        setSelectedProfiles();
        e.originalEvent.stopPropagation();
        if (map) {
          map.flyTo({
            center: [profiles[0].lng, profiles[0].lat],
            zoom: 14,
            speed: 2,
            curve: 1,
            essential: true,
          });
        }
      }}
    />
  );
}

function MiniProfileList(props: { profiles: ProfileCompact[] }) {
  const t = useTranslations("Map");
  return (
    <>
      <Box px={"3"} pb={"2"}>
        <TextV2 textStyle={"Body L"}>
          {t("number_of_therapists", {
            count: props.profiles.length,
          })}
        </TextV2>
      </Box>
      <Flex
        direction={"column"}
        maxHeight={"300px"}
        overflowY={"auto"}
        gap={"3"}
        py={"3"}
      >
        {props.profiles.map((profile) => (
          <MiniProfileCard
            key={profile.user_id}
            profile={profile}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.open(`/find-a-therapist${profile.partial_uri}`, "_blank");
            }}
          />
        ))}
      </Flex>
    </>
  );
}

function MiniProfileCard({
  onClick,
  profile,
}: {
  onClick: (e: React.MouseEvent) => void;
  profile: ProfileCompact;
}) {
  return (
    <a style={{ textDecoration: "none", cursor: "pointer" }} onClick={onClick}>
      <Box
        p={"3"}
        style={{
          backgroundColor: "var(--colorV2-grey-light)",
          border: "1px solid var(--colorV2-grey-medium)",
          borderRadius: "12px",
        }}
      >
        <DirectoryCardContentMiniLayout
          profile={profile}
          showPriceState={"all"}
        />
      </Box>
    </a>
  );
}

function MiniProfileLayout({
  onClick,
  profile,
}: {
  onClick: (e: React.MouseEvent) => void;
  profile: ProfileCompact;
}) {
  return (
    <a style={{ textDecoration: "none", cursor: "pointer" }} onClick={onClick}>
      <Box px={"2"} pt={"2"}>
        <DirectoryCardContentMiniLayout
          profile={profile}
          showPriceState={"all"}
        />
      </Box>
    </a>
  );
}

export { TherapistMap };
