import { search as api } from "@/api";
import LabelCheckbox from "@/design-system/components/LabelCheckbox";
import { Command } from "cmdk";
import React from "react";
import { Box, Button, Flex, Separator } from "@radix-ui/themes";
import { Text } from "@/design-system/components/Text";
import { useTranslations } from "next-intl";
import styles from "./Filter.module.scss";

type ComboboxItem = { id: string; name: string };

type FormComboboxProps = {
  id: string;
  ariaLabel: string;
  placeholder: string;
  select: api.SearchSelect;
  selected?: string[];
  enableShowAll?: boolean;
  onSelectedChange?: (selected: string[]) => void;
  useAutofocus?: boolean;
};

function FilterCombobox(props: FormComboboxProps) {
  const t = useTranslations("Search");
  const allItems = props.select.all.map(toComboboxItem());
  const commonItems = props.select.common.map(toComboboxItem());
  const [showAll, setShowAll] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [selected, setSelected] = React.useState<ComboboxItem[]>(
    props.selected
      ? allItems.filter((i) =>
          props.selected?.map(String).includes(i.id.toString()),
        )
      : [],
  );

  const toggleSelected = (item: ComboboxItem) => {
    const index = selected.indexOf(item);
    let selectedSnapshot;
    if (index < 0) {
      selectedSnapshot = [...selected, item];
    } else {
      selectedSnapshot = selected.filter((s) => s !== item);
    }
    props.onSelectedChange?.(selectedSnapshot.map((s) => s.id));
    setSelected(selectedSnapshot);
  };

  const hasResultsMinimum = inputValue.length > 1;
  const selectedItemIds = selected.map((e) => e.id);
  const sourceItems = allItems.filter((e) => !selectedItemIds.includes(e.id));

  const defaultShortList =
    commonItems.length > 0
      ? commonItems.filter((e) => !selectedItemIds.includes(e.id))
      : sourceItems.slice(0, 10);

  const shortListIds = defaultShortList.map((e) => e.id);
  const defaultExtendedList = sourceItems.filter(
    (e) => !shortListIds.includes(e.id),
  );

  // https://github.com/radix-ui/primitives/issues/1342#issuecomment-1368472699
  return (
    <Command
      filter={(value, search) => {
        if (value.includes("selected-")) {
          return 1;
        } else {
          const indexOfMatch = value
            .toLowerCase()
            .split(" ")
            .findIndex((word) => word.startsWith(search.toLowerCase()));
          if (indexOfMatch === -1) {
            return 0;
          }
          return 1.0 / (indexOfMatch + 1);
        }
      }}
    >
      {/* prevent focus on input by default, to prevent keyboard from taking over the screen on mobile */}
      <button
        style={{ position: "absolute", opacity: 0 }}
        autoFocus={props.useAutofocus}
        tabIndex={-1}
      />

      <Flex
        id={props.id}
        aria-label={props.id}
        direction={"row"}
        wrap={"wrap"}
        gap={"2"}
        className={styles.comboboxInputWrapper}
      >
        <Command.Input
          placeholder={props.placeholder}
          value={inputValue}
          onValueChange={(v) => {
            setInputValue(v);
          }}
        />
      </Flex>
      {hasResultsMinimum && (
        <Command.Empty>
          <Box width={"100%"} mt={"3"}>
            <Text large color={"mint"}>
              {t("filter_no_results")}
            </Text>
          </Box>
        </Command.Empty>
      )}
      <Command.List className={styles.commandList}>
        {selected.map((item) => (
          <LabelCheckbox
            key={`selected_items_${props.id}_${item.id}_${item.name}`.toLocaleLowerCase()}
            name={props.id}
            value={item.id}
            label={item.name}
            className="CheckboxRoot"
            checked={true}
            onCheckedChange={() => {
              setInputValue("");
              toggleSelected(item);
            }}
          />
        ))}
        {!hasResultsMinimum &&
          defaultShortList.map((item) => (
            <LabelCheckbox
              key={`default_short_${props.id}_${item.id}_${item.name}`.toLocaleLowerCase()}
              name={props.id}
              value={item.id}
              label={item.name}
              onCheckedChange={() => toggleSelected(item)}
            />
          ))}
        {!hasResultsMinimum && showAll && (
          <>
            <Separator size="4" my={"3"} />
            {defaultExtendedList.map((item) => (
              <LabelCheckbox
                key={`default_extended_${props.id}_${item.id}_${item.name}`.toLocaleLowerCase()}
                name={props.id}
                value={item.id}
                label={item.name}
                onCheckedChange={() => toggleSelected(item)}
              />
            ))}
          </>
        )}
        {hasResultsMinimum &&
          sourceItems.map((item) => (
            <Command.Item
              key={`combo_item_${props.id}_${item.id}_${item.name}`.toLocaleLowerCase()}
              value={item.name}
            >
              <LabelCheckbox
                name={props.id}
                value={item.id}
                label={item.name}
                onCheckedChange={() => {
                  setInputValue("");
                  toggleSelected(item);
                }}
              />
            </Command.Item>
          ))}
        {props.enableShowAll &&
          defaultExtendedList.length > 0 &&
          !hasResultsMinimum && (
            <Button
              variant={"outline"}
              radius={"large"}
              size={"3"}
              type={"button"}
              onClick={() => setShowAll(!showAll)}
            >
              {showAll
                ? t("filters.navigation.show_less")
                : t("filters.navigation.show_x_more", {
                    count: defaultExtendedList.length,
                  })}
            </Button>
          )}
      </Command.List>
    </Command>
  );
}

function toComboboxItem(): (option: api.SearchOption) => ComboboxItem {
  return (option: api.SearchOption) => ({
    id: option.search_filter_id.toString(), // keep toString, sometimes this is a number which breaks selection logic
    name: option.name,
  });
}
export { FilterCombobox };
