import * as api from "@/api/api";
import { SupportedLocales } from "@/i18n/i18n.config";

/**
 * Frequently Asked Questions
 * Currently have the structure
 * - Parent Category
 *  - Category
 *  - FAQ
 *
 *  We allow various representations to avoid recursive structures depending
 *  on the use case.
 */

type FrequentlyAskedQuestion = {
  slug: string;
  question: string;
  answer: string;
};

type FrequentlyAskedQuestionWithCategory = FrequentlyAskedQuestion & {
  category: FrequentlyAskedCategoryWithParent;
};

type FrequentlyAskedCategory = {
  slug: string;
  name: string;
};

type FrequentlyAskedCategoryWithParent = FrequentlyAskedCategory & {
  parent: FrequentlyAskedParentCategory;
};

type FrequentlyAskedCategoryWithFaqs = FrequentlyAskedCategory & {
  faqs: FrequentlyAskedQuestion[];
};
type FrequentlyAskedParentCategory = {
  slug: string;
  name: string;
};

type FrequentlyAskedParentCategoryWithChildren =
  FrequentlyAskedParentCategory & {
    children: FrequentlyAskedCategoryWithFaqs[];
  };

async function faqs(
  locale: SupportedLocales,
): Promise<api.ServerResponse<FrequentlyAskedParentCategoryWithChildren[]>> {
  return await api.render(
    fetch(`${process.env.NEXT_PUBLIC_APP_API_URL}/faq?locale=${locale}`),
  );
}

/**
 * Fetch a single FAQ. This will return the category and parent category as well so we can render the breadcrumb.
 * @param slug
 * @param locale
 */
async function faq(
  slug: string,
  locale: SupportedLocales,
): Promise<api.ServerResponse<FrequentlyAskedQuestionWithCategory>> {
  return await api.render(
    fetch(
      `${process.env.NEXT_PUBLIC_APP_API_URL}/faq/${slug}?locale=${locale}`,
    ),
  );
}

async function faqForClients(
  locale: SupportedLocales,
): Promise<api.ServerResponse<FrequentlyAskedCategoryWithFaqs[]>> {
  return await api.render(
    fetch(
      `${process.env.NEXT_PUBLIC_APP_API_URL}/faq/for_clients/embedded?locale=${locale}`,
    ),
  );
}

export { faqs, faqForClients, faq };
export type {
  FrequentlyAskedCategory,
  FrequentlyAskedCategoryWithFaqs,
  FrequentlyAskedQuestion,
  FrequentlyAskedParentCategory,
  FrequentlyAskedParentCategoryWithChildren,
};
