import { useFormatter, useTranslations } from "next-intl";
import { FilterMenuProps } from "@components/Search/Filter/FilterMenuProps";
import { FilterMenu } from "./FilterMenu";
import { Box } from "@radix-ui/themes";
import React, { useCallback } from "react";
import config from "@components/Search/config.search";
import { search as api } from "@/api";
import styled from "styled-components";
import * as Slider from "@radix-ui/react-slider";

function FilterMenuCost({ filters, setFilters }: FilterMenuProps) {
  const t = useTranslations("Search.filters.cost");

  return (
    <FilterMenu
      buttonLabel={t("label")}
      contentLabel={t("label")}
      totalSelected={0}
      fixedWidth={true}
    >
      <CostFilter filters={filters} setFilters={setFilters} />
    </FilterMenu>
  );
}

function CostFilter({
  filters,
  setFilters,
}: {
  filters: api.SearchParams["filters"];
  setFilters: (f: api.SearchParams["filters"]) => void;
}) {
  const onChangeValues = useCallback(
    (values: [number, number]) => {
      setFilters({
        ...filters,
        price: {
          min: values[0],
          max: values[1],
        },
      });
    },
    [setFilters, filters],
  );

  const actualMin = filters?.price?.min;
  const actualMax = filters?.price?.max;
  const defaultMin = 50;
  const defaultMax = 125;
  const isMinProvided = typeof actualMin === "number";
  const isMaxProvided = typeof actualMax === "number";
  const values = [
    isMinProvided ? actualMin : isMaxProvided ? config.price_min : defaultMin,
    isMaxProvided ? actualMax : defaultMax,
  ] as [number, number];

  return (
    <Box pb={"5"}>
      <PriceSlider values={values} setValues={onChangeValues} />
    </Box>
  );
}

function PriceSlider({
  values,
  setValues,
}: {
  values: [number, number];
  setValues: (values: [number, number]) => void;
}) {
  const format = useFormatter();

  const formatPrice = (value: number) => {
    return format.number(value, {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const [labelValueMinForLayout, labelValueMaxForLayout] =
    thumbsLabelsPositionShamelessUiHack(values);
  return (
    <SliderContainer>
      <SliderRoot
        id="input_price"
        min={config.price_min}
        max={config.price_max}
        defaultValue={values}
        step={config.price_step}
        minStepsBetweenThumbs={1}
        onValueChange={setValues}
        name="price_range"
      >
        <SliderTrack>
          <SliderRange />
        </SliderTrack>
        <SliderThumb />
        <SliderThumb />
      </SliderRoot>
      <PriceIndicatorMin
        label={formatPrice(values[0])}
        value={labelValueMinForLayout}
        min={config.price_min}
        max={config.price_max}
      />
      <PriceIndicatorMax
        label={formatPrice(values[1])}
        value={labelValueMaxForLayout}
        min={config.price_min}
        max={config.price_max}
      />
    </SliderContainer>
  );
}

const SliderContainer = styled.div`
  position: relative;
`;

type PriceIndicatorProps = {
  label: string;
  value: number;
  min: number;
  max: number;
};
const PriceIndicator = styled.div<PriceIndicatorProps>`
  position: absolute;
  color: var(--green);
  font-weight: 600;
  font-size: var(--font-size-20);

  &:before {
    content: "${(props) => props.label}";
  }
`;

const PriceIndicatorMin = styled(PriceIndicator)<PriceIndicatorProps>`
  margin-left: -10px;

  left: ${(props) =>
    ((props.value - props.min) / (props.max - props.min)) * 100}%;
`;

const PriceIndicatorMax = styled(PriceIndicator)<PriceIndicatorProps>`
  margin-right: -15px;

  right: ${(props) =>
    100 - ((props.value - props.min) / (props.max - props.min)) * 100}%;
`;

const SliderRoot = styled(Slider.Root)`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
  height: 40px;
`;

const SliderTrack = styled(Slider.Track)`
  background-color: var(--green-low);
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
  height: 12px;
`;

const SliderRange = styled(Slider.Range)`
  position: absolute;
  background-color: var(--green);
  border-radius: 9999px;
  height: 100%;
`;

const SliderThumb = styled(Slider.Thumb)`
  display: block;
  width: 20px;
  height: 20px;
  background-color: white;
  box-shadow: 0 2px 10px var(--blackA7);
  border-radius: 10px;

  &:hover {
    background-color: var(--green-low);
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 5px var(--blackA8);
  }
`;

// !!! shameless hack
//
// To avoid price tags overlapping under the slider's thumbs, the value
// used to compute the label's position is a value determined empirically.
//
// A better solution would be to resolve this at the UI level depending on
// the actual text's size.
function thumbsLabelsPositionShamelessUiHack(values: number[]) {
  let adjustedPositionsMin = values[0];
  let adjustedPositionsMax = values[1];
  if (values[1] - values[0] === config.price_step) {
    if (values[1] !== config.price_max) {
      adjustedPositionsMax = values[1] + 10;
    } else {
      adjustedPositionsMin = values[0] - 20;
    }
  }
  return [adjustedPositionsMin, adjustedPositionsMax];
}

export { FilterMenuCost, CostFilter };
