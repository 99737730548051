import * as api from "@/api/api";
import { Referral } from "@/api/entities/referral";

async function validateReferral(
  coupon_name: string,
  invite_reference?: string,
): Promise<api.ServerResponse<Referral>> {
  let url = `${process.env.NEXT_PUBLIC_APP_API_URL}/referrals/validate/${coupon_name}`;
  if (invite_reference) {
    url += `/${invite_reference}`;
  }
  // we should remove the locale from here after the next deployment COM-1273
  return await api.render<Referral>(
    fetch(url, {
      method: "POST",
    }),
  );
}

export { validateReferral };
