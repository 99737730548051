import * as api from "./api";

type TherapistSession = {
  id: string;
  uid: string;
  display_name: string;
  created_at: string;
};

type TherapistSessionsListValue = { sessions: TherapistSession[] };
async function list(): Promise<api.ServerResponse<TherapistSessionsListValue>> {
  return await api.render(
    fetch(`/api/therapy_session`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
    (value: TherapistSessionsListValue) => ({
      sessions: value.sessions
        .filter(isSessionOutOfDate)
        .sort((a, b) => (a.created_at < b.created_at ? 1 : -1)),
    }),
  );
}

// See https://github.com/Complicated-Life/Its-Complicated/blob/b1b60310c07c8955a26af859e3b87143e93ea362/client/src/app/components/waiting-room/waiting-room.component.ts#L73
function isSessionOutOfDate(session: TherapistSession): boolean {
  const hours_ago_3 = Date.now() - 1000 * 60 * 60 * 3;
  return new Date(session.created_at).getTime() > hours_ago_3;
}

async function getGetStreamVideoToken(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  callId: string,
  userId: string,
): Promise<api.ServerResponse<{ video_token: string }>> {
  return await api.render(
    fetch(`/api/video/token?user_id=` + userId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  );
}

async function validateWaitingRoomSession(
  sessionUid: string,
): Promise<api.ServerResponse<TherapistSession>> {
  return await api.render(
    fetch(`/api/therapy_session/validate_waiting_room`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        session_uid: sessionUid,
      }),
    }),
  );
}

async function createOnlineSession(
  therapistProfileId: string,
  clientUserId: string,
  status: "waiting",
): Promise<api.ServerResponse<TherapistSession>> {
  return await api.render(
    fetch(`/api/therapy_session`, {
      body: JSON.stringify({
        profile: therapistProfileId,
        user: clientUserId,
        status: status,
      }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  );
}

async function createOnlineGuestSession(
  therapistProfileId: string,
  guestName: string,
): Promise<api.ServerResponse<TherapistSession>> {
  return await api.render(
    fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/onlinesessions/public`, {
      body: JSON.stringify({
        profile: therapistProfileId,
        username: guestName,
      }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  );
}
async function getAnynonymousOnlinesessionWsToken(): Promise<
  api.ServerResponse<{ token: string }>
> {
  return await api.render(
    fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/api/onlinesessions/public/anonymous`,
      {
        body: "{}",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      },
    ),
  );
}

export {
  getAnynonymousOnlinesessionWsToken,
  getGetStreamVideoToken,
  list,
  createOnlineSession,
  createOnlineGuestSession,
  validateWaitingRoomSession,
};
export type { TherapistSession };
