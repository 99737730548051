import * as RadixTheme from "@radix-ui/themes";
import styled from "styled-components";
import React from "react";
import { IconButton as ThemesIconButton } from "@radix-ui/themes/dist/cjs/components/icon-button";

interface ThemesIconButtonProps
  extends React.ComponentPropsWithoutRef<typeof ThemesIconButton> {}

interface IconButtonOwnProps {
  primary?: true;
  secondary?: true;
  tertiary?: true;
  tiny?: true;
  large?: true;
}

interface IconButtonProps
  extends Omit<ThemesIconButtonProps, "color" | "size">,
    IconButtonOwnProps {}

function IconButton(props: IconButtonProps) {
  let size: "1" | "2" | "3";
  if (props.tiny) {
    size = "1";
  } else if (props.large) {
    size = "3";
  } else {
    size = "2";
  }

  if (props.primary) {
    return <PrimaryIconButton {...props} size={size} />;
  }
  if (props.secondary) {
    return <SecondaryIconButton {...props} size={size} />;
  }
  if (props.tertiary) {
    return <TertiaryIconButton {...props} size={size} />;
  }

  return <RadixTheme.IconButton {...props} size={size} />;
}

const PrimaryIconButton = styled(RadixTheme.IconButton)`
  color: var(--primary-color);
  background-color: var(--green);

  &:hover {
    background-color: var(--green-over);
  }

  &:disabled {
    background-color: var(--grey-light);
    color: var(--grey-dark-1);
  }
`;

const SecondaryIconButton = styled(RadixTheme.IconButton)`
  background-color: var(--green-low);
  color: var(--black);

  &:hover {
    background-color: var(--green-low-over);
  }
`;

const TertiaryIconButton = styled(RadixTheme.IconButton)`
  background: none;
  color: var(--black);

  &:hover {
    background-color: var(--grey-medium-over);
  }
`;

export { IconButton };
