import { useTranslations } from "next-intl";
import { FilterCombobox } from "./FilterCombobox";
import { FilterMenu } from "./FilterMenu";
import { Text } from "@/design-system/components/Text";
import { FilterCheckboxList } from "@components/Search/Filter/FilterCheckboxList";
import { Flex, Separator } from "@radix-ui/themes";
import React from "react";
import { search as api } from "@/api";
import { FilterMenuProps } from "@components/Search/Filter/FilterMenuProps";

function FilterMenuAdvanced({ options, filters, setFilters }: FilterMenuProps) {
  const t = useTranslations("Search.filters.advanced");

  const totalSelected =
    (filters?.genders?.length || 0) +
    (filters?.identities?.length || 0) +
    (filters?.insurances?.length || 0) +
    (filters?.isEap ? 1 : 0);

  return (
    <FilterMenu
      buttonLabel={t("label")}
      contentLabel={t("label")}
      totalSelected={totalSelected}
      fixedWidth={true}
    >
      <AdvancedFilter
        options={options}
        filters={filters}
        setFilters={setFilters}
      />
    </FilterMenu>
  );
}

function StandaloneAdvancedFilter({
  options,
  filters,
  setFilters,
}: FilterMenuProps) {
  return (
    <AdvancedFilter
      options={options}
      filters={filters}
      setFilters={setFilters}
    />
  );
}

function AdvancedFilter({
  options,
  filters,
  setFilters,
}: {
  options: api.SearchFiltersOptions;
  filters: api.SearchParams["filters"];
  setFilters: (f: api.SearchParams["filters"]) => void;
}) {
  const t = useTranslations("Search.filters.advanced");

  const EAP_ITEM = {
    search_filter_id: "payment_option_EAP",
    name: t("payment_options.eap"),
    analytics_id: "payment_option_EAP",
  };

  return (
    <Flex direction={"column"} gap={"3"}>
      <Text tiny light>
        {t("gender.label")}
      </Text>
      <FilterCheckboxList
        name="format"
        type={"wrapped_row"}
        options={options.genders.all}
        selected={filters?.genders}
        onSelectedChange={(selected) =>
          setFilters({
            ...filters,
            genders: selected,
          })
        }
      />

      <Separator size="4" my={"3"} />
      <Text tiny light>
        {t("identity.label")}
      </Text>
      <FilterCombobox
        id="identity_filter_name"
        ariaLabel={t("identity.label")}
        placeholder={t("identity.placeholder")}
        select={options.identities}
        selected={filters?.identities}
        enableShowAll={true}
        onSelectedChange={(selected) =>
          setFilters({
            ...filters,
            identities: selected,
          })
        }
      />

      <Separator size="4" my={"3"} />
      <Text tiny light>
        {t("payment_options.label")}
      </Text>

      <FilterCheckboxList
        name="insurances"
        type={"wrapped_row"}
        options={[...options.insurances.all, EAP_ITEM]}
        selected={[
          ...(filters?.insurances || []),
          filters?.isEap ? "payment_option_EAP" : undefined,
        ].filter((item): item is string => !!item)}
        onSelectedChange={(selected) =>
          setFilters({
            ...filters,
            insurances: selected.filter((e) => e !== "payment_option_EAP"),
            isEap: selected.includes("payment_option_EAP") ? true : undefined,
          })
        }
      />
    </Flex>
  );
}

export { FilterMenuAdvanced, StandaloneAdvancedFilter };
