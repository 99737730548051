import { render, ServerResponse } from "./api";
import { SupportedLocales } from "@/i18n/i18n.config";

type Tag = {
  slug: { de: string; en: string };
  name: { de: string; en: string };
};
type DirectoryTags = {
  cities: Tag[];
  languages: Tag[];
  specialities: Tag[];
  approaches: Tag[];
};

type TranslatedTag = {
  slug: string;
  name: string;
};

async function fetchAndSortTags(
  endpoint: string,
  locale: SupportedLocales,
): Promise<ServerResponse<TranslatedTag[]>> {
  return await render<TranslatedTag[]>(
    fetch(
      `${process.env.NEXT_PUBLIC_APP_API_URL}/directory/tags/${endpoint}?locale=${locale}`,
    ),
    (payload) =>
      payload.sort((a, b) =>
        a.name.trim().localeCompare(b.name.trim(), locale),
      ) || [],
  );
}

async function tags(): Promise<ServerResponse<DirectoryTags>> {
  return await render<DirectoryTags>(
    fetch(`${process.env.NEXT_PUBLIC_APP_API_URL}/directory/tags`),
    (payload) => ({
      cities: payload.cities || [],
      languages: payload.languages || [],
      specialities: payload.specialities || [],
      approaches: payload.approaches || [],
    }),
  );
}

async function cities(
  locale: SupportedLocales,
): Promise<ServerResponse<TranslatedTag[]>> {
  return fetchAndSortTags("cities", locale);
}

async function languages(
  locale: SupportedLocales,
): Promise<ServerResponse<TranslatedTag[]>> {
  return fetchAndSortTags("languages", locale);
}

async function specializations(
  locale: SupportedLocales,
): Promise<ServerResponse<TranslatedTag[]>> {
  return fetchAndSortTags("specialities", locale);
}

async function approaches(
  locale: SupportedLocales,
): Promise<ServerResponse<TranslatedTag[]>> {
  return fetchAndSortTags("approaches", locale);
}

export { tags, cities, languages, specializations, approaches };
export type { DirectoryTags, Tag, TranslatedTag };
